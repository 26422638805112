import React from "react";
import Gallery from '../Gallery';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import "./style.css";

class Orderlist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            maintable: this.props.maintable,
            mobile: this.props.mobile,
        };
    }

    getData() {
        fetch(window.location.origin + "/data/data.json?nocache=" + ( new Date( ).getTime( ) ) )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result,
                        error: null,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );
    }

    class_status(status) {
        let status_class;
        if (status === "НОВЫЙ") {
            status_class = "red_text";
        } else if (status === "СБОРКА") {
            status_class = "yellow_text";
        } else if (status === "ГОТОВ") {
            status_class = "green_text";
        } else {
            status_class = "simple_text";
        }
        return status_class;
    }

    componentDidMount() {
        this.getData();
        this.interval = setInterval(() => this.getData(), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {error, isLoaded, items, maintable, mobile } = this.state;
        let items_render;

        if ( maintable === 0 ) {
            items_render = items;
        } else if ( maintable === 1 ) {
            items_render = items.slice( 0, 14 );
        } else if ( maintable === 2 ) {
            items_render = items.slice( 14, 100 );
        } else {
            items_render = items;
        }

        if (error) {
            return <div><p className="error">Ошибка: {error.message}</p><Gallery/></div>;
        } else if (!isLoaded) {
            return <div>
                <Spinner animation="border" variant="primary" />
            </div>;
        } else {
            if ( items_render.length === 0 ) {
                return (
                    <div className="gall-qrcode-wraper">
                        <Gallery/>
                    </div>

                );
            } else {
                return (
                    <div className="gall-table-wraper">
                        <Gallery mobile={mobile}/>
                        <Table striped hover size="sm">
                            <thead>
                            <tr>
                                <th>Заказ</th>
                                <th>Клиент</th>
                                <th>Статус</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items_render.map(item => (
                                <tr key={item.id}>
                                    <td>{item.order}</td>
                                    <td>{item.partner}</td>
                                    <td className={this.class_status(item.status)}>{item.status}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                );
            }
        }
    }
}

export default Orderlist;
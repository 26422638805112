import React from 'react';
import Gallery from "../../Gallery";
import "./style.css";

function GalleryPage() {
    return (

        <div className="gall-qrcode-wraper App">
            <Gallery/>
        </div>

    )
}

export default GalleryPage;
import React from 'react';
import Orderlist from "../../Orderlist";

function Table1() {
    return (
        <div className="App">
            <Orderlist maintable={1}/>
        </div>
    )
}

export default Table1;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

import '../Home/style.css';

function Home() {
    return (
        <div className="App admin-page">
            <Container>
                <Row>
                    <h1>Табло сборки клиентских заказов</h1>
                </Row>
                <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Основное табло</Card.Title>
                                <Card.Text>
                                    Предназначено для левого монитора в Шоурум. Показывает только первые 14 записей по заказам. Если записи по заказам отсутствуют, показывает галлерею.
                                </Card.Text>
                                <Link to="table1">Перейти к основному табло</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Дополнительное табло</Card.Title>
                                <Card.Text>
                                    Предназначено для правого монитора в Шоурум. Показывает только те записи, следующие после 14 записи по заказам. Если всего записей не более 14-ти, показывает галлерею.
                                </Card.Text>
                                <Link to="table2">Перейти к дополнительному табло</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Табло для мобильных устройств</Card.Title>
                                <Card.Text>
                                    Предназначено для мобильных устройств. Показывает все записи по заказам. Если записи по заказам отсутствуют, показывает галлерею.
                                </Card.Text>
                                <Link to="table">Перейти к табло для мобильных устройств</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Галлерея</Card.Title>
                                <Card.Text>
                                    Всегда показывает галлерею. Сделана на всякий случай.
                                </Card.Text>
                                <Link to="gallery">Перейти к галлерее</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
         </div>
    )
}

export default Home;
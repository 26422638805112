import React from 'react';
import Home from "./component/Pages/Home";
import Table from "./component/Pages/Table";
import Table1 from "./component/Pages/Table1";
import Table2 from "./component/Pages/Table2";
import Gallery from "./component/Pages/Gallerypage";

import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/table">
                    <Table />
                </Route>
                <Route path="/table1">
                    <Table1 />
                </Route>
                <Route path="/table2">
                    <Table2 />
                </Route>
                <Route path="/gallery">
                    <Gallery />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </BrowserRouter>

    );
}

export default App;

import React from 'react';
import Orderlist from "../../Orderlist";
import "./style.css";

function Table() {
    return (
        <div className="App">
            <div className="mobile-table">
                <Orderlist maintable={0} mobile={true}/>
            </div>
        </div>
    )
}

export default Table;
import React from 'react';
import Orderlist from "../../Orderlist";

function Table2() {
    return (
        <div className="App">
            <Orderlist maintable={2}/>
        </div>
    )
}

export default Table2;